// General
$aspect-ratio: 1/2;
$transition-stagger: 0.04s;
$transition-duration: 0.5s;
$intro-transition-duration: 0.25s;
$caption-text-transition-duration: 0.25s;
$caption-text-transition-delay: 0.9s;
$caption-text-exit-duration: 0.25s;
$n-shards: 13;

// Window
$window-width: 85%;
$window-height: 85%;
$y-offset: -5%;

// Backend
$terminal-bar-height: 3.5%;
$terminal-button-radius: 0.65%;
$terminal-button-margin: 0.2%;
$terminal-cursor-width: 1%;
$terminal-cursor-height: 4%;

// Frontend
$header-height: 6%;
$mouse-start-x: 78%;
$mouse-start-y: 24%;
$mouse-base-coords: (
        x1: 0%,
        y1: 0%,
        x2: 2%,
        y2: 2%,
        x3: 1%,
        y3: 4%
);
$search-bar-height: 4%;
$search-bar-width: 24%;
$page-side-margin: 4%;
$content-placeholder-colour-darker: #b6b6b6;
$content-placeholder-colour-dark: #d2d2d2;
$content-placeholder-colour-light: #e1e1e1;
$content-placeholder-height-1: 2.5%;
$content-placeholder-border-radius-1: calc(100vmax * #{$aspect-ratio} * 0.125);
$content-placeholder-height-2: 2.1% ;
$content-placeholder-line-height-2: 1.8;
$content-placeholder-border-radius-2: calc(100vmax * #{$aspect-ratio} * 0.105);
$post-1-height: 22.5%;
$post-2-height: 26%;
$posts-y-margin: 4%;

// PM
$trello-column-width: 14%;
$trello-column-gutter: 2.25%;
$trello-column-padding: calc(var(--shards-vh) * 2);
$trello-card-gutter: calc(var(--shards-vh) * 2);
$trello-card-padding: calc(var(--shards-vh) * 0.75) calc(var(--shards-vh) * 1);
$trello-card-line-height: calc(var(--shards-vh) * 1.25);
$trello-card-line-margin: calc(var(--shards-vh) * 1) 0;
$trello-card-shadow: 0 1px 0 rgba(9,30,66,.25);
$trello-user-diameter: calc(var(--shards-vh) * 3)
