$cerulean-frost: #6d98ba;
$dark-vanilla: #bdcea3;
$ghost-white: #fbfbff;
$gorse: #f9e784;
$isabelline: #ebebeb;
$linen: #f8f0e7;
$meat-brown: #e2c766;
$off-yellow: #faf3dd;
$pale-silver: #e0c2b8;
$tomato: #ed6d52;
$eggshell: #f4f1de;
$wild-blue-yonder: #9aadbf;
$trello-topbar: #0993ac;
$trello-content: #00aecb;
$trello-column-bg: #ebecf0;
$trello-user-bg: #eceeec;

$text-colour: #111111;
