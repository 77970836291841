@import '../../assets/scss/mixins';

.trello-card {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: $trello-card-shadow;
  flex: 0 0 auto;
  margin-bottom: $trello-card-gutter;
  padding: $trello-card-padding;

  .card-line {
    @include trello-card-line('light');
    margin: $trello-card-line-margin;

    &--1 {
      width: 33%
    }

    &--2 {
      width: 66%
    }

    &--3 {
      width: 100%
    }
  }

  .trello-user {
    background-color: $trello-user-bg;
    background-image: url('../../assets/svg/face.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 90%;
    border-radius: 50%;
    height: $trello-user-diameter;
    margin-left: auto;
    width: $trello-user-diameter;
  }
}
