@import 'assets/scss/colours';

* {
  box-sizing: border-box;
}

body {
  color: $text-colour;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans Condensed', sans-serif;
}

h1, h2, p {
  margin: 0;
}

h1 {
  font-size: 7vh;
}

h2 {
  font-size: 4vh;
}

p {
  font-size: 3.5vh;
  line-height: 1.6;
}

a {
  border-bottom: 1px solid black;
  text-decoration: none;
  z-index: 1;

  &:link, &:visited {
    color: inherit;
  }

  &:hover {
    border-bottom-color: $tomato;
    color: $tomato;
  }

  &:active {
    color: inherit;
  }
}

span.accent {
  color: $tomato;
  font-weight: 700;
}
